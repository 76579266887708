import React from "react"
import Form from "../components/form"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Terminal from "terminal-in-react"
import Fade from "react-reveal/Fade"
import Particles from "react-particles-js"

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showForm: false,
      message: `type help for a list of commands.`,
      showBio: false,
      loading: true,
    }
    this.nameEl = React.createRef()
  }

  handleProjects = () => {
    window.open("https://github.com/smallbrownbike")
  }

  componentDidMount() {
    setTimeout(() => this.setState({ loading: false }))
  }

  render() {
    const { showForm, showBio, message, loading } = this.state
    return (
      <Layout>
        <SEO
          description="A software engineer."
          title="Home | Eli Kinsey"
        />
        <div className="home-container">
          <div className="home-content-container">
            <Fade when={!loading} duration={1000}>
              <div className="home-title-section">
                <div>
                  <h1 className="name-title">eli kinsey</h1>
                  <h2 className="title">
                    front-end engineer{" "}
                    <strong style={{ background: "#f1d3d2", color: "white" }}>
                      /
                    </strong>{" "}
                    san francisco bay area
                  </h2>
                </div>
              </div>
            </Fade>

            <div className="home-content">
              <Fade when={!loading} duration={1000}>
                <Terminal
                  actionHandlers={{
                    handleClose: () => {
                      // do something on close
                      return null
                    },
                    handleMaximise: () => {
                      // do something on maximise
                      return null
                    },
                    handleMinimise: () => {
                      // do something on maximise
                      return null
                    },
                  }}
                  prompt="black"
                  color="black"
                  backgroundColor="white"
                  barColor="#eaeaea"
                  style={{
                    fontWeight: "bold",
                    fontSize: "1em",
                    height: "100%",
                  }}
                  commands={{
                    github: this.handleProjects,
                    contact: () => this.setState({ showForm: true }),
                    bio: () => this.setState({ showBio: true }),
                  }}
                  descriptions={{
                    github: "open my github page",
                    show: false,
                    clear: false,
                    contact: "send me an email",
                    bio: "learn about me",
                  }}
                  msg={message}
                  allowTabs={false}
                />
              </Fade>
              <Fade duration={500} when={showForm} bottom>
                <div
                  style={showForm ? {} : { display: "none" }}
                  className="contact-container"
                >
                  <span
                    onClick={() => this.setState({ showForm: false })}
                    className="contact-close"
                  >
                    &times;
                  </span>
                  <div className="contact-form-container">
                    <h1 style={{ marginTop: 0 }}>what's on your mind?</h1>
                    <Form buttonText="let's do it!" />
                  </div>
                </div>
              </Fade>
              <Fade duration={500} when={showBio} bottom>
                <div
                  style={
                    showBio ? { alignItems: "flex-start" } : { display: "none" }
                  }
                  className="contact-container bio"
                >
                  <span
                    onClick={() => this.setState({ showBio: false })}
                    className="contact-close"
                  >
                    &times;
                  </span>
                  <div className="contact-form-container">
                    <h1 style={{ marginTop: 0, marginBottom: 0 }}>
                      hi, i'm eli.
                    </h1>
                    <h2 style={{ fontWeight: 100, marginTop: 0 }}>
                      i build cool things to grow your business.
                    </h2>
                    <p>
                      <strong>things i'm good at:</strong>
                    </p>
                    <ul>
                      <li>react</li>
                      <li>nodejs</li>
                      <li>php</li>
                      <li>vue</li>
                      <li>mysql</li>
                      <li>mongodb</li>
                      <li>gatsby</li>
                      <li>wordpress</li>
                      <li>
                        <i>playing guitar</i>
                      </li>
                    </ul>
                    <p>
                      <strong>things i do:</strong>
                    </p>
                    <ul>
                      <li>build tools to help you work faster</li>
                      <li>build marketing sites to help you grow faster</li>
                      <li>get leads to help you earn faster</li>
                      <li>
                        <i>fast fast to make you fast faster</i>
                      </li>
                    </ul>
                    <h3>
                      wanna talk?{" "}
                      <button
                        onClick={() =>
                          this.setState({ showBio: false, showForm: true })
                        }
                      >
                        shoot me an email
                      </button>
                      .
                    </h3>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
          <div className="shape">
            <div className="hidden-tri"></div>
            <div className="particles-container">
              <Particles
                style={{ height: "100%", position: "absolute", bottom: 0 }}
                height="100%"
                params={{
                  particles: {
                    number: {
                      value: 200,
                      density: {
                        enable: true,
                        value_area: 800,
                      },
                    },
                    color: {
                      value: "#ffffff",
                    },
                    shape: {
                      type: "circle",
                      stroke: {
                        width: 0,
                        color: "#000000",
                      },
                      polygon: {
                        nb_sides: 5,
                      },
                      image: {
                        src: "img/github.svg",
                        width: 100,
                        height: 100,
                      },
                    },
                    opacity: {
                      value: 0.8,
                      random: false,
                      anim: {
                        enable: false,
                        speed: 1,
                        opacity_min: 0.1,
                        sync: false,
                      },
                    },
                    size: {
                      value: 3,
                      random: true,
                      anim: {
                        enable: false,
                        speed: 40,
                        size_min: 0.1,
                        sync: false,
                      },
                    },
                    line_linked: {
                      enable: false,
                      distance: 150,
                      color: "#ffffff",
                      opacity: 0.4,
                      width: 1,
                    },
                    move: {
                      enable: true,
                      speed: 6,
                      direction: "none",
                      random: false,
                      straight: false,
                      out_mode: "out",
                      bounce: false,
                      attract: {
                        enable: false,
                        rotateX: 600,
                        rotateY: 1200,
                      },
                    },
                  },
                  interactivity: {
                    detect_on: "canvas",
                    events: {
                      onhover: {
                        enable: true,
                        mode: "repulse",
                      },
                      onclick: {
                        enable: true,
                        mode: "push",
                      },
                      resize: true,
                    },
                    modes: {
                      grab: {
                        distance: 400,
                        line_linked: {
                          opacity: 1,
                        },
                      },
                      bubble: {
                        distance: 400,
                        size: 40,
                        duration: 2,
                        opacity: 8,
                        speed: 3,
                      },
                      repulse: {
                        distance: 200,
                        duration: 0.4,
                      },
                      push: {
                        particles_nb: 4,
                      },
                      remove: {
                        particles_nb: 2,
                      },
                    },
                  },
                  retina_detect: true,
                }}
              />
            </div>
          </div>{" "}
        </div>
      </Layout>
    )
  }
}

export default IndexPage
